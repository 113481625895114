import React, { Component } from 'react';
import AssinaturaDataService from "../services/assinatura.service";
// import { cpf } from 'cpf-cnpj-validator';
import { format, addHours } from 'date-fns'
import { ptBR } from 'date-fns/locale'
//  import { zonedTimeToUtc,formatInTimeZone, utcToZonedTime  } from 'date-fns-tz';
// import DataTable from 'react-data-table-component';
// import DataTableExtensions from "react-data-table-component-extensions";
// import "react-data-table-component-extensions/dist/index.css";
import { Col, Row, Container, Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import NavTop from './NavTop';
import Header from './Header';
import NavMain from './NavMain';
import getTraducao from '../utils/traducao';

// const columns = [
//     { name: '#', selector: 'id', sortable: true, },
//     { name: 'Título', selector: 'titulo', sortable: true, },
//     { name: 'Descrição', selector: 'descricao', sortable: true, wrap: true, compact: true, },
//     // { name: 'Nota', selector: 'nota', sortable: true, right: true, },
//     { name: 'Valor', selector: 'valor', sortable: true, compact: true, },
//     { name: 'Ativo', selector: 'ativo', sortable: true, right: true, omit: false, compact: true, cell: row => <> {row.ativo === true ? 'X' : ''}</> },
//     { name: 'Ações', compact: true, cell: row => <>{row.id && <Button variant="link" className={"btn text-muted  btn-link p-0 m-0 "} onClick={() => handleButtonClick(row)}><small>Edit</small></Button>}</>, right: true },
// ]
// const handleButtonClick = (state) => {
//     window.location = "/edit-motorista/" + state.id;
// };

class MinhasAssinaturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Assinaturas: [],
            message: "",
            Mensagem: getTraducao("Carregando assinaturas").toUpperCase()

        };

    }

    handleLogout = () => {

    }

    componentDidMount() {
        this.getAssinatura();
    }

    getAssinatura() {
        AssinaturaDataService.getAll()
            .then(response => {
                this.setState({
                    Assinaturas: response.data,
                    Mensagem: 'Nenhuma assinatura para exibir'
                });
            })
            .catch(e => {
                console.log(e);
            });
    }


    render() {


        return (

            <div className="page-wrapper" >
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Minhas assinaturas" ></Header>
                <section className="course-one course-one__teacher-details home-one">
                    <Container>
                        {this.state.Assinaturas.length > 0
                            ?
                            <>
                                <Container fluid={true}>
                                    <Row className={"pull-right"}>
                                        <Col className={"py-2 "}>
                                            <Link to="/nova-assinatura" className="btn pull-right btn-info botao-acao" >+</Link>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Plano</th>
                                                        <th>Assinado em</th>
                                                        <th>Valido até</th>
                                                        <th>Transaction</th>
                                                        <th>Boleto</th>
                                                        <th>Pago</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.Assinaturas.map
                                                            (e =>
                                                                <tr key={e.id}>
                                                                    <td>{e.id}</td>
                                                                    <td>{e.plano === null ? 'Código de assinatura' : e.plano.titulo}</td>
                                                                    <td>{format(addHours(new Date(e.createdAt), 3), 'dd/MM/yyyy', { timeZone: 'America/Sao_Paulo', locale: ptBR })}</td>
                                                                    <td>{e.validUntil === null ? "" : format(addHours(new Date(e.validUntil), 3), 'dd/MM/yyyy', { timeZone: 'America/Sao_Paulo', locale: ptBR })}</td>
                                                                    <td>{e.transaction}</td>
                                                                    <td>{(e.boleto===null || e.paid === 1)?<></>:<><a href={e.boleto} > Imprimir Boleto</a></>}</td>
                                                                    <td>{e.paid === 1 ? 'Sim' : 'Não'}</td>
                                                                </tr>
                                                            )
                                                    }


                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                            :
                            <p>{this.state.Mensagem}</p>
                        }
                    </Container>{/*<!-- /.container -->*/}
                </section>

                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div >
        );
    }
}
export default MinhasAssinaturas;