import React, { Component } from 'react';
import PlanoDataService from "../services/plano.service";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getToken, scrollToSmooth } from '../utils';
import getTraducao from '../utils/traducao';

class BannerPlanosInterna extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planos: [],
            message: ""
        };

    }

    handleLogout = () => {

    }

    componentDidMount() {
        this.getPlanos();
        scrollToSmooth();
    }
    createElements(n, valor) {
        var elements = [];
        for (var i = 1; i <= n; i++) {
            elements.push(<li ><><div style={{ fontWeight: 'bolder' }}>{i}x de R$ {valor / i} no cartão ou no boleto bancário</div>{i > 1 ? <>*acréscimo de R$ 2,00 em cada parcela no boleto</> : <></>}</></li>);
        }
        return elements;
    }
    getPlanos() {
        PlanoDataService.getAll()
            .then(response => {
                this.setState({
                    planos: response.data
                });
            })
            .catch(e => {
                console.log(e);
            });
    }


    render() {

        return (

            <div style={{ backgroundColor: "#f7f7f7" }}>

                <section className="course-osne__top-titsle home-sone assinatura-top pt-5"  >
                    <div className="container ">
                        <Row>
                            <Col></Col>
                            <Col xs={10}>
                                <div className="block-title mb-0 font-size-24 lh-38 ">
                                    {getTraducao("Oferecemos a você ou à sua instituição de ensino ou outra, algumas opções de planos para que você possa escolher o que melhor se adapta à sua necessidade.")}<br></br><br></br>
                                    {getTraducao("Em todos os planos você tem o acesso a todos os recursos do dicionário constituindo de verbetes, fotos, vídeos, áudios e ilustrações. A diferença de um plano para o outro consiste somente no período de tempo que você terá disponibilidade de acesso e o custo.")}<br></br><br></br>
                                    <h2 className="block-dtitle__titsle font-size-30">
                                        {getTraducao("Escolha a sua opção e faça a sua assinatura!")}</h2>{/*<!-- /.block-title__title -->*/}
                                </div>{/*<!-- /.block-title -->*/}
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>{/*<!-- /.container -->*/}

                </section>{/*<!-- /.course-one__top-title -->*/}

                <section className="course-ones course-one__teacher-details assinatura-planos home-one">
                    <Container fluid={false} className="mt-2 pt-5 pb-5  ">
                        <Row className=" pt-0 mt-0">
                            {this.state.planos && this.state.planos.length > 0
                                ?
                                <>
                                    {
                                        this.state.planos.map(data =>
                                            <Col className="item mx-auto px-auto " xs={12} sm={12} md={12} lg={4} key={data.id}>
                                                <div className="course-one__single color-1 ">
                                                    <div className={"course-one__image color-" + data.order}>
                                                        {/* <img src={"./assets/images/" + data.imagem} alt="" /> */}
                                                    </div>{/*<!-- /.course-one__image -->*/}
                                                    <div className="course-one__content">
                                                        <h2 className="course-one__title font-size-36 lh-50 ">{data.titulo}</h2>

                                                        <div className="course-one__stars">
                                                            <span className="course-one__stars-wrap">

                                                            </span>
                                                            {/*<!--span className="course-one__count">4.8</span-->*/}{/*<!-- /.course-one__count -->*/}
                                                            {/*<!--span className="course-one__stars-count">250</span-->*/}{/*<!-- /.course-one__stars-count -->*/}
                                                        </div>{/*<!-- /.course-one__stars -->*/}
                                                        <div className="course-one__meta font-size-36 lh-36">
                                                            R$ {data.valor}<br />
                                                        </div>{/*<!-- /.course-one__meta -->*/}
                                                        <div className="course-one__meta-description-interna font-size-18 lh-21">
                                                            {data.conteudo}

                                                        </div>

                                                        <Link to={(getToken() === undefined || getToken() === "" || getToken() === null) ? "cadastre" : `nova-assinatura/${data.id}`} className="course-one__link font-size-20 lh-21">{getTraducao("Assinar")}</Link>{/*<!-- /.course-one__link -->*/}
                                                    </div>{/*<!-- /.course-one__content -->*/}
                                                </div>{/*<!-- /.course-one__single -->*/}
                                            </Col>
                                        )
                                    }

                                </>
                                //   </OwlCarousel>
                                :
                                <p>{getTraducao("Carregando planos").toUpperCase()}</p>
                            }
                        </Row>
                    </Container>{/*<!-- /.container -->*/}
                </section>
            </div >
        );
    }
}
export default BannerPlanosInterna;