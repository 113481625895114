import React from 'react';
import { decode } from 'html-entities';
import { createMarkup } from '../utils';

const Paragraph = props => {
    const { value, dangerous = false } = props;


    return (

            dangerous
            ?

            <div dangerouslySetInnerHTML={createMarkup(decode(value))}></div>
            :

            <div>{value}</div>


    );
}
export default Paragraph