import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/instrumento.css";
import { Col, Container, Row } from 'react-bootstrap';
import InstrumentoDataService from "../services/instrumento.service";
// import TextInput from './TextInput'
// import CheckBox from './CheckBox'
// import TextArea from './TextArea'
// import Botao from './Botao'
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import { Link, NavLink } from 'react-router-dom';
import getTraducao from '../utils/traducao';

export default class Instrumentos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Instrumentos: []
            , InstrumentosFiltro: []
            , letra: '-'
        }
        this.clickDay = this.clickDay.bind(this);
        this.filtrarAgenda = this.filtrarAgenda.bind(this);
    }

    clickDay = e => {
        this.setState({ letra: e.target.getAttribute('data-key') })
        this.filtrarAgenda(e.target.getAttribute('data-key'));
    }
    filtrarAgenda = (letra) => {
        if (this.state.Instrumentos.length <= 10) {
            this.setState({
                InstrumentosFiltro: this.state.Instrumentos
            });
        }
        else
            if (this.state.Instrumentos.length > 0) {
                this.setState({
                    InstrumentosFiltro: this.state.Instrumentos.filter(x => x.NomeDicionario.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace('`', '').startsWith(letra) || x.NomeDicionario.normalize('NFD').replace(/[\u0300-\u036f]/g, "").startsWith(letra.toLowerCase()))
                });
            }

    }

    componentDidMount() {
        this.carregarInstrumentos();
    }

    carregarInstrumentos() {
        const query = new URLSearchParams(this.props.location.search);
        let letra = (this.props.match.params.letra !== undefined ? this.props.match.params.letra : query.has('letra') ? query.get('letra') : 'A');
        this.setState({ letra: letra.toUpperCase() })

        InstrumentoDataService.getAll()
            .then(response => {
                console.log(response.data.length)
                localStorage.setItem('NroInstrumentos', response.data.length)
                this.setState({
                    Instrumentos: response.data
                });
                this.filtrarAgenda(letra)
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Instrumentos" ></Header>
                <div className='container-wrapper'>
                    <Container fluid={true} className="mt-2 pt-0 pb-0 Bibliografia">
                        {/* Navegação */}
                        <Row className=" pt-0 mt-0 ">
                            <Col className='' md={1} lg={2}></Col>
                            <Col xs={12} sm={12} md={10} lg={8} className="header-navigation text-center ">
                                <div className="main-navigation  px-auto mx-auto pr-0">
                                    <ul className=" navigation-box text-center">
                                        {/* Instrumentos | Glossário | Outros nomes | Bibliografia */}
                                        <li><NavLink to="/instrumentos" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Instrumentos")}</NavLink></li>
                                        <li><NavLink to="/glossario" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Glossário")}</NavLink></li>
                                        <li><NavLink to="/sinonimos" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Outros nomes")}</NavLink></li>
                                        <li><NavLink to="/bibliografia" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Bibliografia")}</NavLink></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={10} lg={2}></Col>
                        </Row>
                        {/* Navegação */}
                    </Container>
                    <Container fluid={false} className={`instrumento ${(this.state.Instrumentos.length <= 10) ? 'd-none' : ''}`}>
                        {/* ALFABETO */}
                        <Row className=" pt-0 mt-0">
                            {/* <Col></Col> */}
                            <Col xs={12} className="header-navigation ">
                                <Container fluid={false}>
                                    <Row>
                                        <Col className="">
                                            <ul className=" navigation-box mx-auto ">
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=A" onClick={this.clickDay} className={this.state.letra==="A"?"current":""}  data-key="A">A</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=B" onClick={this.clickDay} className={this.state.letra==="B"?"current":""}  data-key="B">B</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=C" onClick={this.clickDay} className={this.state.letra==="C"?"current":""}  data-key="C">C</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=D" onClick={this.clickDay} className={this.state.letra==="D"?"current":""}  data-key="D">D</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=E" onClick={this.clickDay} className={this.state.letra==="E"?"current":""}  data-key="E">E</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=F" onClick={this.clickDay} className={this.state.letra==="F"?"current":""}  data-key="F">F</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=G" onClick={this.clickDay} className={this.state.letra==="G"?"current":""}  data-key="G">G</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=H" onClick={this.clickDay} className={this.state.letra==="H"?"current":""}  data-key="H">H</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=I" onClick={this.clickDay} className={this.state.letra==="I"?"current":""}  data-key="I">I</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=J" onClick={this.clickDay} className={this.state.letra==="J"?"current":""}  data-key="J">J</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=K" onClick={this.clickDay} className={this.state.letra==="K"?"current":""}  data-key="K">K</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=L" onClick={this.clickDay} className={this.state.letra==="L"?"current":""}  data-key="L">L</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=M" onClick={this.clickDay} className={this.state.letra==="M"?"current":""}  data-key="M">M</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=N" onClick={this.clickDay} className={this.state.letra==="N"?"current":""}  data-key="N">N</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=O" onClick={this.clickDay} className={this.state.letra==="O"?"current":""}  data-key="O">O</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=P" onClick={this.clickDay} className={this.state.letra==="P"?"current":""}  data-key="P">P</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=Q" onClick={this.clickDay} className={this.state.letra==="Q"?"current":""}  data-key="Q">Q</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=R" onClick={this.clickDay} className={this.state.letra==="R"?"current":""}  data-key="R">R</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=S" onClick={this.clickDay} className={this.state.letra==="S"?"current":""}  data-key="S">S</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=T" onClick={this.clickDay} className={this.state.letra==="T"?"current":""}  data-key="T">T</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=U" onClick={this.clickDay} className={this.state.letra==="U"?"current":""}  data-key="U">U</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=V" onClick={this.clickDay} className={this.state.letra==="V"?"current":""}  data-key="V">V</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=W" onClick={this.clickDay} className={this.state.letra==="W"?"current":""}  data-key="W">W</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=X" onClick={this.clickDay} className={this.state.letra==="X"?"current":""}  data-key="X">X</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=Y" onClick={this.clickDay} className={this.state.letra==="Y"?"current":""}  data-key="Y">Y</NavLink></li>
                                                <li className="alfabeto px-1 m-auto"><NavLink to="../instrumentos?letra=Z" onClick={this.clickDay} className={this.state.letra==="Z"?"current":""}  data-key="Z">Z</NavLink></li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            {/* <Col></Col> */}
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col className={`letra ${(this.state.Instrumentos.length <= 10) ? 'd-none' : ''}`}><h1>{this.state.letra}</h1></Col>
                        </Row>
                    </Container>
                    <Container fluid={false}>
                        <Row className="px-auto mx-auto text-center px-0 pb-5 pt-2">
                            {this.state.InstrumentosFiltro.map(a =>
                                <Col key={a.id} lg={3} xs={5} className="text-left pt-0 pt-0  pl-1 instrumento"><Link to={"../instrumento?instrumento=" + a.NomeOriginal}>{a.NomeDicionario}</Link></Col>
                            )}
                        </Row>
                    </Container>
                </div>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
