const TOKEN_KEY = 'Token';
export const isLogin = ()  => { return localStorage.getItem(TOKEN_KEY) ? true : false; }
export const getToken = () => { return localStorage.getItem(TOKEN_KEY); }
export const getEmail = () => { return localStorage.getItem('Email'); }
export const getNome = () => { return localStorage.getItem('Nome'); }
export const getNroInstrumentos = () => { return localStorage.getItem('NroInstrumentos'); }
export const getLang = () => { return localStorage.getItem('Idioma') === null ? 'pt-BR' : localStorage.getItem('Idioma') }
export const createMarkup = (data) => {
    return { __html: data };
}
export const scrollToSmooth = () => {
    console.log('Scroll')
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      })
}
export const validaCpfCnpj = (val) => {
    var num = val.trim();
    num = num.replace(/\./g, '');
    num = num.replace('-', '');
    num = num.replace(/\./g, '');
    num = num.replace('-', '');
    num = num.replace('/', '');
    // num = num.split('');



    if (val.length === 11) {
        return cpf.isValid(num);
    } else if (val.length === 14) {

    }
    else {
        return cnpj.isValid(num);
    }
}

export const logout = () => {

    localStorage.removeItem('Id');
    localStorage.removeItem('Nome');
    localStorage.removeItem('Email');
    localStorage.removeItem('Role');
    localStorage.removeItem('Token');
    localStorage.removeItem('IdVeiculo');
    localStorage.removeItem('IdMotorista');

    window.location.reload(true);
}
