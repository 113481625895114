import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/Bibliografia.css";
import { Col, Container, Row } from 'react-bootstrap';
import AssinaturaDataService from "../services/assinatura.service";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import { NavLink } from 'react-router-dom';
import getTraducao from '../utils/traducao';

export default class Bibliografia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1
        };
        this.state = {
            Bibliografias: []
            , BibliografiasFiltro: []
            , letra: 'A'
            , filtragem: 'A Música Linguagem,'
            , AssinaturaAtiva: false

        }
    }
    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages });
    }
    onChangeText = (event) => {
        this.setState({
            filtragem: event.target.value
        })
    }


    componentDidMount() {
        this.carregarAssinatura();
    }
    createMarkup = (data) => {
        return { __html: data };
    }
    carregarAssinatura() {

        AssinaturaDataService.count()
            .then(response => {
                var element = response.data;
                this.setState({
                    AssinaturaAtiva: element.count !== 0
                })

            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Bibliografias" ></Header>
                <div className='container-wrapper'>
                    <Container fluid={true} className="mt-2 pt-0 pb-0 Bibliografia">
                        {/* Navegação */}
                        <Row className=" pt-0 mt-0">
                            <Col className='' md={1} lg={2}></Col>
                            <Col xs={12} sm={12} md={10} lg={8} className="header-navigation text-center ">
                                <div className="main-navigation  px-auto mx-auto pr-0">
                                    <ul className=" navigation-box text-center">
                                        {/* Instrumentos | Glossário | Outros nomes | Bibliografia */}
                                        <li><NavLink to="/instrumentos" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Instrumentos")}</NavLink></li>
                                        <li><NavLink to="/glossario"    activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Glossário")}</NavLink></li>
                                        <li><NavLink to="/sinonimos"    activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Outros nomes")}</NavLink></li>
                                        <li><NavLink to="/bibliografia" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Bibliografia")}</NavLink></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col className='' md={1} lg={2}></Col>
                        </Row>
                        {/* Navegação */}
                    </Container>
                    {
                        this.state.AssinaturaAtiva
                            ?
                            <Container fluid={false}>
                                <Row className="px-auto mx-auto text-center px-3 pb-5 pt-2 vh-100">
                                    <Col>

                                        <iframe src={`${'https://rodweb.com.br/ReferenciasBibliograficas.pdf'}#view=fitH`} title="ReferenciasBibliograficas" height="100%" width="100%" />


                                    </Col>
                                </Row>

                            </Container>
                            :
                            <Container fluid={false}>
                                <Row className="px-auto mx-auto text-center px-3 pb-5 pt-2 ">
                                    <Col>

                                    <> {getTraducao("Nenhum registro localizado")}</>


                                    </Col>
                                </Row>

                            </Container>
                    }
                </div>

                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
