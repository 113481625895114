/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import getTraducao from '../utils/traducao';
import packageJson from '../../package.json';
// global.appVersion = packageJson.version;
class FooterMenu extends Component {
    constructor(props) {
        super(props);
        ;
        this.scrollTo = this.scrollTo.bind(this);

    }

    handleLogout = () => {
        ;
    }
    scrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    }

    componentDidMount() {
        // console.log(packageJson.version)
        ;
    }

    render() {
        return (

            <footer className="site-footer">
                <div className="site-footer__upper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <div className="container footer-widget footer-widget__contact list-unstyled ">
                                    <Row>
                                        <Col lg={2} xs={12} className={"footer-widget__link-list"}><NavLink to="/sobre">{getTraducao("Sobre nós")}</NavLink></Col>
                                        <Col lg={3} xs={12} className={"footer-widget__link-list"}><NavLink to="/politica-privacidade">{getTraducao("Política de Privacidade")}</NavLink></Col>
                                        <Col lg={4} xs={12} className={"footer-widget__link-list"}><NavLink to="/termos-condicoes">{getTraducao("Termos de uso e condições gerais")}</NavLink></Col>
                                        <Col lg={1} xs={12} className={"footer-widget__link-list"}><NavLink to="/contato">{getTraducao("Contato")}</NavLink></Col>
                                        <Col lg={1} xs={12} className={"footer-widget__link-list"}><NavLink to="/assinatura">{getTraducao("Assinar")}</NavLink></Col>
                                        <Col lg={1} xs={12} className={"footer-widget__link-list"}><NavLink to="#">{getTraducao("FAQ")}</NavLink></Col>
                                    </Row>
                                </div>{/*<!-- /.footer-widget */}
                            </div>{/*<!-- /.col-lg-3 */}
                        </div>{/*<!-- /.row */}
                    </div>{/*<!-- /.container */}
                </div>{/*<!-- /.site-footer__upper */}

                <div className="site-footer__bottom">
                    <div className="container d-none d-lg-flex " >
                        <p className="site-footer__copy font-size-14 lh-14">&copy; Copyright 2022 by<a  href="https://cantaroarteeducacao.com.br/" className='px-0 mx-0'  without="true" rel="noopener noreferrer" target={"_blank"}>&nbsp;Cântaro Arte&Educação </a>&nbsp;-&nbsp;<small > Version - {packageJson.version} </small></p>
                        <div className="site-footer__social">
                            <Link to="#" onClick={this.scrollTo} data-target="html" className="scroll-to-target site-footer__scroll-top"><i className="kipso-icon-top-arrow"></i></Link>
                            {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                            <a href="#" ><i className="fab fa-facebook-square"></i></a>
                            {/* <Link to="#"><i className="fab fa-pinterest-p"></i></Link> */}
                            <a href="#"><i className="fab fa-instagram"></i></a>
                        </div>{/*<!-- /.site-footer__social */}
                        {/*<!-- /.site-footer__copy */}
                    </div>{/*<!-- /.container */}

                    <Container className='d-block d-lg-none  pt-5'>
                        <Row>

                            <Col lg={6} xs={12} className='text-center'>
                                <p className="site-footer__copy font-size-14 lh-14 pb-5 text-center">&copy; Copyright 2022 by<a  className='px-0 mx-0'  href="https://cantaroarteeducacao.com.br/" target={"_blank"} without="true" rel="noopener noreferrer">&nbsp;Cântaro Arte&Educação </a></p>
                            </Col>
                            <Col lg={6} xs={12} className='text-center'>
                                <p className="site-footer__copy font-size-14 lh-14 pb-5  "><small > Version - {packageJson.version}</small></p>
                            </Col>
                            <Col lg={6} xs={12}>
                                <div className="site-footer__social">
                                    <Link to="#" onClick={this.scrollTo} data-target="html" className="scroll-to-target site-footer__scroll-top"><i className="kipso-icon-top-arrow"></i></Link>
                                    {/* <Link to="#"><i className="fab fa-twitter"></i></Link> */}
                                    <Link to="#"><i className="fab fa-facebook-square"></i></Link>
                                    {/* <Link to="#"><i className="fab fa-pinterest-p"></i></Link> */}
                                    <Link to="#"><i className="fab fa-instagram"></i></Link>
                                </div>{/*<!-- /.site-footer__social */}

                            </Col>
                        </Row>
                    </Container>
                </div>{/*<!-- /.site-footer__bottom */}

            </footer>


        );
    }
}
export default FooterMenu;