import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/contato.css";
import { Alert, Col, Container, Row } from 'react-bootstrap';
import TextInput from './TextInput'
import CheckBox from './CheckBox'
import TextArea from './TextArea'
import Botao from './Botao'
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import UserDataService from "../services/user.service";
import getTraducao from '../utils/traducao';
import { scrollToSmooth } from '../utils';

class Contato extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: [],
      escolhas: [],
      planos: [],
      Sexo: ""
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    let cadastro =
    {
      nome: event.target.Nome.value,
      from: event.target.Email.value,
      subject: "E-mail enviado pelo formulário de contatos do site.",
      text: event.target.Mensagem.value,
      Politica: event.target.Politica.value === undefined ? 0 : 1,
      Atualizacoes: event.target.Atualizacoes.value === undefined ? 0 : 1
    };

    UserDataService.enviarEmail(cadastro).then(res => {
      if (res.status === 200) {
        // console.log(res.data);
        Alert("Email enviado com sucesso.")
      }
    }).catch(function (error) {
      var $ = require("jquery");


      let message = 'Ocorreu um erro inesperado.';
      if (error.response !== undefined) {
        message = error.response.data.message;
        $(".has-error-message").text(message);

      }
      else
        if (error.message !== undefined) {
          message = error.message;
          $(".has-error-message").text(message);
        }
    });

  }

  componentDidMount() {
    scrollToSmooth();
  }
  render() {

    return (
      <div className="page-wrapper">
        <NavTop></NavTop>
        <NavMain></NavMain>
        <Header title="Contato" ></Header>



        <Container fluid={true} className="mt-2 pt-5 pb-5 contato sobre-nos">
          <Row className=" pt-0 mt-0">

            <Col xs={12} className="mx-auto px-auto ">
              <div className="card card-contato my-4 py-4">
                <div className="card-body">
                  <Container>
                    <Row className="form-group">
                      <Col className="px-auto " xs={12}>
                        <Row>
                          <Col xs={12} className="">
                            <h3 className="white">{getTraducao("ENTRE EM")} </h3>
                            <h1>{getTraducao("CONTATO")}</h1>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <div className="container pb-5">
                        <Row>

                          <Col xs={12}>
                            <div className="mb-0 font-size-24 lh-38 ">
                            {getTraducao("Caso queira nos enviar uma sugestão ou tirar uma dúvida, envie sua mensagem preenchendo o formulário abaixo, e retornaremos o mais breve possível. A sua mensagem é importante para nós.")}
                            </div>{/*<!-- /.block-title -->*/}
                          </Col>

                        </Row>
                      </div>{/*<!-- /.container -->*/}
                    </Row>
                    <form onSubmit={this.handleSubmit}>
                      <section className="course-ones course-one__teacher-details assinatura-planoss home-one">
                        <Row className="mb-0 pb-0">
                          <Col xs={12} >
                            <TextInput
                              type="text"
                              name="Nome"
                              id="Nome"
                              required="required"
                              className="w-100 px-2 mb-0 pb-0"
                              formGroupClass="mb-1"
                              placeholder={getTraducao("Nome:")}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className=" ">
                            <TextInput
                              type="email"
                              name="Email"
                              id="Email"
                              required="required"
                              className="w-100 px-2"
                              placeholder="Email:"
                              formGroupClass="mb-1"
                            />
                          </Col>
                          {/* <Col xs={4} className="pl-1 ">
                        <TextInput
                          type="text"
                          className="w-100  px-2"
                          placeholder="Telefone:"
                          formGroupClass="mb-1"
                        />
                      </Col> */}
                        </Row>
                        <Row className="mb-0 pb-0">
                          <Col xs={12} >
                            <TextArea

                              name="Mensagem"
                              required="required"
                              id="Mensagem"
                              className="w-100 px-2 mb-0 pb-0"
                              formGroupClass="mb-0"
                              placeholder={getTraducao("Mensagem:")}
                            />
                          </Col>
                        </Row>
                        <Row className="pt-0 mt-0">
                          <Col xs={8}>
                            <Col xs={12} className="text-left pl-0">
                              <CheckBox
                                id="Politica"
                                name="Politica"
                                value={getTraducao("Concordo com a Política de Privacidade e com os Termos de Uso e Condições Gerais")}
                                valueLink=""
                                LinkTo=""
                                linkClassName="font-weight-bold"
                                className="mr-3"
                                // required={true}
                                labelClassName="py-0 my-0"
                                formGroupClass="mb-0 py-0 my-0 form-group-contato"
                              />
                              <CheckBox
                                id="Atualizacoes"
                                name="Atualizacoes"
                                value={getTraducao("Gostaria de receber notícias, novidades e atualizações da Cântaro")}
                                valueLink=""
                                LinkTo="#"
                                linkClassName="font-weight-bold"
                                className="mr-3"
                                // required={true}
                                labelClassName="py-0 my-0"
                                formGroupClass="mb-0 form-group-contato"
                              />
                            </Col>
                            <Col xs={12} className="text-left pl-0">

                            </Col>
                          </Col>
                          <Col xs={4} className="text-right">
                            <Botao className="btn btn-primary pull-right px-5 mt-3 thm-btn banner-one__btn" submit={"true"} value={getTraducao("ENVIAR")}></Botao>
                          </Col>
                        </Row>
                      </section>
                    </form >
                  </Container>

                </div>
              </div>
            </Col>

          </Row>
        </Container>

        <FooterMenu></FooterMenu>
        <Footer></Footer>
      </div>
    );
  }

}
export default Contato;