import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/instrumento.css";
import { Col, Container, Row, Image, Modal, Button } from 'react-bootstrap';
import InstrumentoDataService from "../services/instrumento.service";
import IdiomaDataService from "../services/idioma.service";
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import { NavLink } from 'react-router-dom';
import imgAudio from './../assets/images/Audio.png';
import imgImagem from './../assets/images/Imagem.png';
import imgVideo from './../assets/images/Video.png';
import imgAudioHover from './../assets/images/Audio_hover.png';
import imgImagemHover from './../assets/images/Imagem_hover.png';
import imgVideoHover from './../assets/images/Video_hover.png';
import Paragraph from './Paragraph';
import getTraducao from '../utils/traducao';
import { getNroInstrumentos, scrollToSmooth } from '../utils';

export default class Instrumento extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Instrumentos: []
            , instrum: ''
            , Loading: true
            , Error: false
            , Idioma: []
            , InstrumentosLang: []
            , InstrumentosFiltro: []
            , letra: ''
            , Anexo: 'ilustracao'
            , isOpen: false
        }
        this.clickIcone = this.clickIcone.bind(this);
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    createMarkup = (data) => {
        return { __html: data };
    }
    clickIcone = e => {
        this.setState({ Anexo: e.target.getAttribute('data-key') })
        this.openModal()
    }



    componentDidMount() {
        // let instrumento = (this.props.match.params.instrumento !== undefined ? this.props.match.params.instrumento : '-');
        // this.setState({ letra: instrumento.toUpperCase() })
        // console.log('componentDidMount')
        this.carregarIdiomas();
    }
    carregarIdiomas() {
        IdiomaDataService.getAll()
            .then(response => {
                // console.log('carregarIdiomas')
                // console.log(response.data)
                this.setState({ Idioma: response.data });
                this.carregarInstrumentos();
            })
            .catch(e => { console.log(e); })
    }
    carregarInstrumentos() {
        const query = new URLSearchParams(this.props.location.search);
        let instrumento = (this.props.match.params.instrumento !== undefined ? this.props.match.params.instrumento : query.has('instrumento') ? query.get('instrumento') : '-');

        // this.setState({ letra: instrumento.toUpperCase() })
        InstrumentoDataService.findByTitle(instrumento, (this.state.lang === undefined ? 'pt-BR' : this.state.lang))
            .then(response => {
                // console.log('carregarInstrumentos')
                // console.log(response.data)
                // console.log(response.data.length)
                if (response.data.length > 0) {
                    this.setState({
                        instrum: response.data[0],
                        letra: response.data[0].NomeDicionario.toUpperCase()
                    });
                    this.carregarInstrumentosIdiomas();
                    scrollToSmooth()
                }
                else {
                    this.setState({ Error: true });
                }
            })
            .catch(e => {
                console.log(e);
            });
    }
    carregarInstrumentosIdiomas() {
        let instrumento = (this.props.match.params.instrumento !== undefined ? this.props.match.params.instrumento : '-');
        // this.setState({ letra: instrumento.toUpperCase() })

        InstrumentoDataService.findByTitleAndLang(instrumento, (this.state.lang === undefined ? 'pt-BR' : this.state.lang))
            .then(response => {
                // console.log('carregarInstrumentosIdiomas')
                // console.log(response.data)
                this.setState({ InstrumentosLang: response.data, Loading: false });
            })
            .catch(e => {
                console.log(e);
            });
    }
    static renderContents(data) {
        return (
            <>
                <Container fluid={false}>
                    <Row className="px-auto mx-auto text-center px-0 pb-0 pt-2">
                        <Col xs={2} className="text-left ml-0 pl-0">
                            {/* <div className=" float-left mr-3 pr-3 w-100">
                                {
                                    data.state.instrum.Linkilustracao !== '' && data.state.instrum.Linkilustracao !== null
                                        ?
                                        <Image id={"imagem"} className="box-conteudos p-0 w-100"
                                            src={"https://drive.google.com/uc?export=view&id=" + data.state.instrum.Linkilustracao.replace("https://drive.google.com/file/d/", "").replace("/view?usp=sharing", "")} />
                                        :
                                        <></>
                                }

                            </div>
                            <br></br> */}
                            <b>{getTraducao("Nome Original")}</b><br></br>
                            {data.state.instrum.NomeOriginal}<br></br>
                            <b>{getTraducao("Nome")} {/*data.state.instrum.Idioma*/}</b><br></br>
                            {data.state.instrum.NomeDicionario}<br></br>
                            {/* /Idiomas */}
                            {
                                data.state.InstrumentosLang.map(
                                    i =>
                                        <div key={i.Idioma}>
                                            <b>Nome {i.Idioma}</b><br></br>
                                            {i.NomeOriginal}<br></br>
                                        </div>
                                )
                            }
                            {/* / */}
                            <b>{getTraducao("Outros nomes")}</b><br></br>
                            {!data.state.instrum.Sinonimos ? '-' : data.state.instrum.Sinonimos}<br></br>
                            <b>{getTraducao("Classificação")}</b><br></br>
                            <Paragraph dangerous={true} value={data.state.instrum.ClassificacaoGeral}></Paragraph><br></br>
                            {/* <h1>
                                <b>
                                    <u>
                                        {
                                            (getRole() === 'ROLE_ADMIN')
                                                ? data.state.instrum.published === 1
                                                    ? 'Publicado'
                                                    : 'Em revisão'
                                                : ''
                                        }
                                    </u>
                                </b>
                            </h1> */}
                            <Container fluid={false} className=" p-0 m-0 ">
                                <Row className="px-auto mx-auto text-left px-0 pb-0 pt-2">
                                    {
                                        data.state.instrum.Linkfoto1 !== null && data.state.instrum.Linkfoto1 !== undefined
                                            ?
                                            <Col xs={4} className=" p-0 m-0 "><button className="btn btn-right pull-right p-0 m-0 " to="#" onClick={data.clickIcone} data-key={'imagem'}><b><i className="material-icons  pl-0 p-0 " onClick={data.clickIcone} data-key={'imagem'}><Image onClick={data.clickIcone} data-key={'imagem'} id={"imagem"} className="b-0 " src={data.state.Anexo === 'imagem' ? imgImagemHover : imgImagem} /></i></b></button> </Col>
                                            : ""
                                    }
                                    {
                                        data.state.instrum.Linkaudio !== null && data.state.instrum.Linkaudio !== undefined
                                            ?
                                            <Col xs={4} className=" p-0 m-0 "><button className="btn btn-right pull-right p-0 m-0 " to="#" onClick={data.clickIcone} data-key={'audio'} ><b><i className="material-icons  p-0 pl-0" onClick={data.clickIcone} data-key={'audio'} ><Image onClick={data.clickIcone} data-key={'audio'} id={"audio"} className="b-0 " src={data.state.Anexo === 'audio' ? imgAudioHover : imgAudio} /></i></b></button></Col>
                                            : ""
                                    }
                                    {
                                        data.state.instrum.Linkvideo !== null && data.state.instrum.Linkvideo !== undefined
                                            ?
                                            <Col xs={4} className=" p-0 m-0 "><button className="btn btn-right pull-right p-0 m-0 " to="#" onClick={data.clickIcone} data-key={'video'} ><b><i className="material-icons  p-0 pl-0" onClick={data.clickIcone} data-key={'video'} ><Image onClick={data.clickIcone} data-key={'video'} id={"video"} className="b-0 " src={data.state.Anexo === 'video' ? imgVideoHover : imgVideo} /></i></b></button></Col>
                                            : ""
                                    }
                                </Row>
                            </Container>

                        </Col>
                        <Col xs={10} className="text-left ml-0 pl-0">
                            <div className=" float-left mr-2 pr-3 w-25">
                                {
                                    data.state.instrum.Linkilustracao !== '' && data.state.instrum.Linkilustracao !== null
                                        ?
                                        <Image id={"imagem"} className="box-conteudo p-0 w-100"
                                            src={"https://drive.google.com/uc?export=view&id=" + data.state.instrum.Linkilustracao.replace("https://drive.google.com/file/d/", "").replace("/view?usp=sharing", "")} />
                                        :
                                        <></>
                                }
                            </div>
                            <div className="text-justify">
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.PrincipaisMateriais === null ? "" : data.state.instrum.PrincipaisMateriais.trim()}></Paragraph>} {data.state.instrum.PrincipaisMateriais !== null && data.state.instrum.PrincipaisMateriais.trim() !== '' ? <span></span> : ''}</div>
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.Variacoes === null ? "" : data.state.instrum.Variacoes.trim()}>          </Paragraph>} {data.state.instrum.Variacoes !== null && data.state.instrum.Variacoes.trim() !== '' ? <span></span> : ''}</div>
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.TecnicaConvencional === null ? "" : data.state.instrum.TecnicaConvencional.trim()}></Paragraph>} {data.state.instrum.TecnicaConvencional !== null && data.state.instrum.TecnicaConvencional.trim() !== '' ? <span></span> : ''}</div>
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.TecnicaEstendida === null ? "" : data.state.instrum.TecnicaEstendida.trim()}>   </Paragraph>} {data.state.instrum.TecnicaEstendida !== null && data.state.instrum.TecnicaEstendida.trim() !== '' ? <span></span> : ''}</div>
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.DadosGeograficos === null ? "" : data.state.instrum.DadosGeograficos.trim()}>   </Paragraph>} {data.state.instrum.DadosGeograficos !== null && data.state.instrum.DadosGeograficos.trim() !== '' ? <span></span> : ''}</div>
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.ContextoMusical === null ? "" : data.state.instrum.ContextoMusical.trim()}>    </Paragraph>} {data.state.instrum.ContextoMusical !== null && data.state.instrum.ContextoMusical.trim() !== '' ? <span></span> : ''}</div>
                                <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.Curiosidades === null ? "" : data.state.instrum.Curiosidades.trim()}>       </Paragraph>} {data.state.instrum.Curiosidades !== null && data.state.instrum.Curiosidades.trim() !== '' ? <span></span> : ''}</div>
                            </div>

                        </Col>
                    </Row>
                    <Row className="px-auto mx-auto text-center px-0 pb-5 pt-2">
                        <Col xs={12} className="text-left ml-0 pl-0 text-justify">
                            {/* <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.TecnicaEstendida}></Paragraph>} {data.state.instrum.TecnicaEstendida !== null && data.state.instrum.TecnicaEstendida.trim() !== '' ? <br></br> : ''}</div>
                            <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.DadosGeograficos}></Paragraph>} {data.state.instrum.DadosGeograficos !== null && data.state.instrum.DadosGeograficos.trim() !== '' ? <br></br> : ''}</div>
                            <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.ContextoMusical}></Paragraph>} {data.state.instrum.ContextoMusical !== null && data.state.instrum.ContextoMusical.trim() !== '' ? <br></br> : ''}</div>
                            <div className="text-justify">{<Paragraph dangerous={true} value={data.state.instrum.Curiosidades}></Paragraph>} {data.state.instrum.Curiosidades !== null && data.state.instrum.Curiosidades.trim() !== '' ? <br></br> : ''}</div> */}
                        </Col>
                    </Row>
                    <Modal centered={true} fullscreen={false} size={'lg'} show={data.state.isOpen} onHide={data.closeModal}>
                        {/* <Modal.Header closeButton>*/}
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        {/*</Modal.Header> */}
                        <Modal.Body>
                            {/* Woohoo, you're reading this text in a modal! */}
                            {
                                data.state.Anexo === 'imagem' && data.state.instrum.Linkfoto1 !== '' && data.state.instrum.Linkfoto1 !== null
                                    ?
                                    <>
                                        <Image id={"imagem"} className="box-conteudo-modal p-0 w-100" src={"https://drive.google.com/uc?export=view&id=" + data.state.instrum.Linkfoto1.replace("https://drive.google.com/file/d/", "").replace("/view?usp=sharing", "")} />
                                        <>{(data.state.instrum.fotografia1 !== null && data.state.instrum.fotografia1.trim !== '') ? <div className='execucao pl-3'> {getTraducao("Fotografia")} {getTraducao("Por")} {data.state.instrum.fotografia1.replace('Por','' )}</div> : <></>}</>
                                        <>{(data.state.instrum.fotografia1fonte !== null && data.state.instrum.fotografia1fonte.trim !== '') ? <div className='fonte  pl-3'>{getTraducao("Fonte")}: {data.state.instrum.fotografia1fonte}<br></br></div> : <></>}</>

                                    </>
                                    :
                                    <></>
                            }
                            {
                                data.state.Anexo === 'video' && data.state.instrum.Linkvideo !== '' && data.state.instrum.Linkvideo !== null
                                    ?
                                    <>
                                        <video className="box-conteudo-modal pl-3 w-100" controls>
                                            <source
                                                src={"https://drive.google.com/uc?export=view&id=" + data.state.instrum.Linkvideo.replace("https://drive.google.com/file/d/", "").replace("/view?usp=sharing", "")}
                                                type="video/mp4"
                                            />
                                        </video>
                                        <>{(data.state.instrum.videoexecucao !== null && data.state.instrum.videoexecucao.trim !== '') ? <div className='execucao pl-3'> {getTraducao("Execução")}: {data.state.instrum.videoexecucao}</div> : <></>}</>
                                        <>{(data.state.instrum.videofonte !== null && data.state.instrum.videofonte.trim !== '') ? <div className='fonte  pl-3'>   {getTraducao("Fonte")}: {data.state.instrum.videofonte}<br></br></div> : <></>}</>

                                    </>
                                    :
                                    <></>
                            }
                            {
                                data.state.Anexo === 'audio' && data.state.instrum.Linkaudio !== '' && data.state.instrum.Linkaudio !== null
                                    ?
                                    <>
                                        <audio className="box-conteudo-modal p-0 w-100" title="Song" controls>
                                            <source
                                                src={"https://drive.google.com/uc?export=view&id=" + data.state.instrum.Linkaudio.replace("https://drive.google.com/file/d/", "").replace("/view?usp=sharing", "")}
                                                type="audio/mpeg"
                                            />
                                        </audio>
                                        <>{(data.state.instrum.audioexecucao !== null && data.state.instrum.audioexecucao.trim !== '') ? <div className='execucao pl-3'> {getTraducao("Execução")}: {data.state.instrum.audioexecucao}</div> : <></>}</>
                                        <>{(data.state.instrum.audiofonte !== null && data.state.instrum.audiofonte.trim !== '') ? <div className='fonte  pl-3'>   {getTraducao("Fonte")}: {data.state.instrum.audiofonte}<br></br></div> : <></>}</>

                                    </>
                                    :
                                    <></>
                            }
                        </Modal.Body>
                        <Modal.Footer closeButton>

                            <Button variant="secondary" onClick={data.closeModal}>{getTraducao("Fechar")}</Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </>
        )
    }

    render() {
        let contents =
            this.state.Loading
                ?
                this.state.Error
                    ?
                    <Container className="pb-5">
                        <Row>
                            <Col ><h1>{" Instrumento não localizado."}</h1></Col>
                        </Row>
                    </Container>
                    :
                    <Container className="pb-5">
                        <Row>
                            <Col ><h1>{" Loading..."}</h1></Col>
                        </Row>
                    </Container>
                :
                Instrumento.renderContents(this);

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title={this.state.letra} ></Header>
                <div className='container-wrapper'>
                    <Container fluid={true} className="mt-2 pt-0 pb-0 Bibliografia">
                        {/* Navegação */}
                        <Row className=" pt-0 mt-0 ">
                            <Col className='' md={1} lg={2}></Col>
                            <Col xs={12} sm={12} md={10} lg={8} className="header-navigation text-center ">
                                <div className="main-navigation  px-auto mx-auto pr-0">
                                    <ul className=" navigation-box text-center">
                                        {/* Instrumentos | Glossário | Outros nomes | Bibliografia */}
                                        <li><NavLink to="/instrumentos" activeClassName="current" activeStyle={{ opacity: 1 }} className={`current`} >{getTraducao("Instrumentos")}</NavLink></li>
                                        <li><NavLink to="/glossario"    activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Glossário")}</NavLink></li>
                                        <li><NavLink to="/sinonimos"    activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Outros nomes")}</NavLink></li>
                                        <li><NavLink to="/bibliografia" activeClassName="current" activeStyle={{ opacity: 1 }} >{getTraducao("Bibliografia")}</NavLink></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={10} lg={2}></Col>
                        </Row>
                        {/* Navegação */}
                    </Container>
                    <Container fluid={false} className={`instrumento ${(getNroInstrumentos() <= 10) ? `d-none ${getNroInstrumentos()}` : `d-block ${getNroInstrumentos()}`}`}>
                        {/* <Container fluid={false} className="instrumento"> */}
                        {/* ALFABETO */}
                        <Row className=" pt-0 mt-0">
                            {/* <Col></Col> */}
                            <Col xs={12} className="header-navigation ">
                                <Container fluid={false}>
                                    <Row>
                                        <Col className="">
                                            <ul className=" navigation-box mx-auto ">
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=A" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="A">A</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=B" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="B">B</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=C" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="C">C</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=D" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="D">D</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=E" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="E">E</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=F" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="F">F</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=G" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="G">G</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=H" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="H">H</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=I" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="I">I</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=J" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="J">J</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=K" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="K">K</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=L" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="L">L</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=M" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="M">M</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=N" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="N">N</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=O" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="O">O</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=P" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="P">P</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=Q" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="Q">Q</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=R" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="R">R</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=S" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="S">S</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=T" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="T">T</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=U" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="U">U</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=V" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="V">V</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=W" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="W">W</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=X" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="X">X</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=Y" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="Y">Y</NavLink></li>
                                                <li className="alfabeto px-1  m-auto"><NavLink to="../instrumentos?letra=Z" activeClassName="current" activeStyle={{ fontWeight: "bold", color: "red" }} data-key="Z">Z</NavLink></li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            {/* <Col></Col> */}
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col className="instrumento-letra"><h1>{this.state.letra}</h1></Col>
                        </Row>
                    </Container>
                    <>
                        {contents}
                    </>
                </div>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
