import React, { Component } from 'react';
import PlanoDataService from "../services/plano.service";
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import TextInput from './TextInput';
// import Select from './Select';
import Botao from './Botao';
import LoginDataService from "../services/login.service";
import UserDataService from "../services/user.service";
import { DirectPayment } from 'pagseguro-react';
import psgService from '../services/psg.service';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Header from './Header';
import FooterMenu from './FooterMenu';
import Footer from './Footer';
import getTraducao from '../utils/traducao';
class NovaAssinatura extends Component {



    constructor(props) {
        super(props);
        this.state = {
            // messages: [],
            message: [],
            escolhas: [],
            select: 0,
            planos: [],
            Plano: -1,
            Sexo: "",

            // {/*
            //     this.state = {
            //     planos: [],
            //     message: [],
            //     cadastro: {},
            //     escolhas: [],
            session: '-1',
            // env: 'sandbox',
            env: 'production',

            sender: {
                name: '',
                email: '',
                phone: {
                    areaCode: '',
                    number: '',
                },
                document: {
                    type: '',
                    value: ''
                },
            },

            shipping: {
                type: 3,
                cost: 0.00,
                street: '',
                number: null,
                complement: '',
                district: '',
                city: '',
                state: '',
                country: 'Brasil',
                postalCode: ''
            },

            billing: {
                street: '',
                number: 0,
                complement: '',
                district: '',
                city: '',
                state: '',
                country: 'Brasil',
                postalCode: ''
            },

            items: [],
            exclude: ['ONLINE_DEBIT'],
            hiddenShippingForm: 1,
            hiddenBillingForm: 1,
            creditCard: {
                maxInstallmentNoInterest: 12 // parcelas com desconto
            },
            extraAmount: 0.00,
            reference: 'Plano Anual Rodrigo '
        };

        this.setPlano = this.setPlano.bind(this);
        this.setSexo = this.setSexo.bind(this);
        this.setPlanoDataKey = this.setPlanoDataKey.bind(this);
        this.onFocusDate = this.onFocusDate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.voltar = this.voltar.bind(this);

        this.isPlanosSelecionado = this.isPlanosSelecionado.bind(this);
    }
    onSubmit(event) {

        psgService.createPayments(event)
            .then(response => {

                this.setState({
                    submitted: true,
                    mensagem: response
                });
                alert('O seu plano, está sendo processado, aguarde..');
                window.location = "/minhas-assinaturas";
            })
            .catch(e => {
                console.log(e);
            });

    }
    onError(event) {
        alert(event)
    }
    handleLogout = () => {

    }

    componentDidMount() {
        this.getSender();
        this.getPlanos();
        this.getSession();

        this.setState({
            Plano: (this.props.match.params.plano !== undefined ? this.props.match.params.plano : -1)
        })

    }
    createElements(n, valor) {
        var elements = [];
        for (var i = 1; i <= n; i++) {
            elements.push(<li key={i + valor + n}><><div style={{ fontWeight: 'bolder' }}>{i}x de R$ {valor / i} no cartão ou no boleto bancário</div>{i > 1 ? <>*acréscimo de R$ 2,00 em cada parcela no boleto</> : <></>}</></li>);
        }
        return elements;
    }
    getPlanos() {
        PlanoDataService.getAll()
            .then(response => {
                this.setState({
                    planos: response.data
                });
                response.data.map(e => {
                    this.setState({
                        escolhas: [...this.state.escolhas, { 'id': e.id, 'value': 0, 'parcelas': e.parcelas, 'titulo': e.titulo, 'descricao': e.descricao, valor: e.valor }]
                    })
                    // console.log(e.id)
                    return e.id
                })
                this.setState({ message: [] })


            })
            .catch(e => {
                console.log(e);
            });
    }

    getSender() {
        UserDataService.get()
            .then(response => {
                let userData = response.data;

                this.setState({

                    sender: {
                        name: userData.nome,
                        email: userData.email,
                        phone: {
                            areaCode: userData.areaCode.replaceAll('(', '').replaceAll(')', ''),
                            number: userData.number.replaceAll('.', '').replaceAll('-', ''),
                        },
                        document: {
                            type: (userData.CPF.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '').length > 11 ? 'CNPJ' : 'CPF'),
                            value: userData.CPF.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
                        },
                    },

                    shipping: {
                        type: 3,
                        cost: 0.00,
                        street: userData.Endereco,
                        number: userData.Numero,
                        complement: userData.Complemento,
                        district: userData.Bairro,
                        city: userData.Cidade,
                        state: userData.Estado,
                        country: 'Brasil',
                        postalCode: userData.CEP.replace('.', '').replace('-', '')
                    },

                    billing: {
                        street: userData.Endereco,
                        number: userData.Numero,
                        complement: userData.Complemento,
                        district: userData.Bairro,
                        city: userData.Cidade,
                        state: userData.Estado,
                        country: 'Brasil',
                        postalCode: userData.CEP.replace('.', '').replace('-', '')
                    },
                })



            })
            .catch(e => {
                console.log(e);
            });
    }

    getPlano(id) {
        PlanoDataService.get(id)
            .then(response => {
                let e = response.data
                this.setState({
                    items: [{
                        id: e.id,
                        description: e.titulo,
                        quantity: 1,
                        amount: e.valor,
                    }],

                    creditCard: {
                        maxInstallmentNoInterest: e.parcelas // parcelas com desconto
                    },
                    reference: e.titulo
                });
                // console.log(this.state.items)

                this.setState({ message: [] })


            })
            .catch(e => {
                console.log(e);
            });
    }

    getSession() {

        // console.log(this.state.items)

        if (this.state.session === '-1') {
            psgService.getSession()
                .then(response => {
                    var XMLParser = require('react-xml-parser');
                    var xml = new XMLParser().parseFromString(response.data);    // Assume xmlText contains the example XML

                    this.setState({
                        session: xml.getElementsByTagName('id')[0].value
                    });
                    // console.log(response.data)
                    this.setState({ message: [] })
                })
                .catch(e => {
                    console.log(e);
                    this.setState({
                        session: '-1'
                    });
                });
        }
    }
    voltar(event) {
        this.setState({ Plano: -1 })
    }
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        let cadastro =
        {
            nome: event.target.Nome.value,
            escola: event.target.Escola.value,
            CPF: event.target.CPF.value,
            dataNascimento: event.target.DataNascimento.value,
            Profissao: event.target.Profissao.value,
            Sexo: event.target.Sexo.value,
            email: event.target.Email.value,
            // username: event.target.username.value,
            password: event.target.Senha.value,
            CEP: event.target.CEP.value,
            Endereco: event.target.Endereco.value,
            Numero: event.target.Numero.value,
            Complemento: event.target.Complemento.value,
            Bairro: event.target.Bairro.value,
            Cidade: event.target.Cidade.value,
            Estado: event.target.Estado.value,
            Pais: event.target.Pais.value,

        };
        this.setState({ message: [] })
        let messages = []

        if (this.state.Plano === 0)
            messages.push({ 'id': 3, 'value': 'Favor escolher um plano.' })

        if (event.target.Senha.value !== event.target.SenhaConfirma.value)
            messages.push({ 'id': 4, 'value': 'As senhas devem ser iguais.' })

        this.setState({ message: messages })

        if (messages.length === 0)
            LoginDataService.signup(cadastro).then(res => {
                if (res.status === 200) {
                    // console.log(res.data);

                    window.location = "/login";
                }
            }).catch(function (error) {

                let message = 'Ocorreu um erro inesperado.';
                if (error.response !== undefined) {
                    message = error.response.data.message;
                }
                else
                    if (error.message !== undefined) {
                        message = error.message;
                    }


                messages.push({ 'id': 4, 'value': message })
                this.setState({ message: message });
            });

    }

    onFocusDate(event) {
        event.target.type = (event.target.type === 'text' ? 'date' : event.target.type)
    }

    setPlano(event) {

        // console.log('setPlano')
        this.setState({
            Plano: event.target.value
        });
    }
    setSexo(event) {

        this.setState({
            Sexo: event.target.value
        });
    }

    setPlanoDataKey(event) {

        let remove = this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key));
        this.state.escolhas.map(data => (data.value = 0));
        this.setState({
            escolhas: [...this.state.escolhas.filter((_, i) => i !== remove), { id: parseInt(event.target.dataset.key), value: 1 }],
            Plano: event.target.dataset.key//event.target.getAttribute('data-key')
        })
        this.getPlano(parseInt(event.target.dataset.key))
        this.getSession();
        console.log(this.state.items)

    }
    // setEscolha(event) {
    //     this.setState({ select: this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key)) })
    //     console.log('escolhas', this.state.escolhas[this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key))]);
    //     this.setState({
    //         items: [
    //             {
    //                 id: this.state.escolhas[this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key))].id,
    //                 description: this.state.escolhas[this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key))].description,
    //                 quantity: 1,
    //                 amount: this.state.escolhas[this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key))].amount,
    //             }],
    //         creditCard: {
    //             maxInstallmentNoInterest: this.state.escolhas[this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(event.target.dataset.key))].parcelas // parcelas com desconto
    //         },
    //     })
    // }
    isPlanosSelecionado(value) {

        let select = this.state.escolhas.map(function (item) { return item.id; }).indexOf(parseInt(value));

        let retorno = -2
        if ((this.state.escolhas !== undefined) && (select !== -1))
            retorno = this.state.escolhas.filter((_, i) => i === select)[0].value
        return retorno;
    }
    render() {
        return (


            <>
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Nova Assinatura" ></Header>

                {/* <form onSubmit={this.handleSubmit}> */}

                <section className="course-osne__top-titsle home-sone assinatura-tops pt-5">
                    <div className="container ">
                        <Row>
                            <Col></Col>
                            <Col xs={10}>
                                <div className="block-title mb-0 font-size-24 lh-38 ">
                                {getTraducao("Oferecemos a você ou à sua instituição de ensino ou outra, algumas opções de planos para que você possa escolher o que melhor se adapta à sua necessidade.")}<br></br><br></br>
                                {getTraducao("Em todos os planos você tem o acesso a todos os recursos do dicionário constituindo de verbetes, fotos, vídeos, áudios e ilustrações. A diferença de um plano para o outro consiste somente no período de tempo que você terá disponibilidade de acesso e o custo.")}<br></br><br></br>
                                    <h2 className="block-dtitle__titsle font-size-30">
                                    {getTraducao("Escolha a sua opção e faça a sua assinatura!")}</h2>{/*<!-- /.block-title__title -->*/}
                                </div>{/*<!-- /.block-title -->*/}
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>{/*<!-- /.container -->*/}

                </section>{/*<!-- /.course-one__top-title -->*/}
                {/* <section className="course-ones course-one__teacher-details assinatura-planoss home-one"> */}
                    <Container fluid={false} className="mt-2 pt-5 pb-5  ">

                        {false
                            ?
                            <Row>
                                <Col xs={12}>
                                    <div className={"has-error control-label"}>
                                        <ul>
                                            <>
                                                {

                                                    this.state.message.map((a, i) => <li key={i}>{a.value}</li>)
                                                }
                                            </>
                                        </ul>
                                    </div>
                                </Col>

                            </Row>
                            :
                            <>
                                {/* * SELECIONE UM PLANO {this.state.Plano}<br />
                                SESSION {this.state.session}<br />
                                PLANO {this.state.escolhas.length}<br />
                                SELECT {this.state.select}<br />
                                ITEMS {this.state.items.length}<br />
                                amount {this.state.items[0].amount}<br />
                                reference {this.state.reference}<br />
                                Parcelas sem juros {this.state.creditCard.maxInstallmentNoInterest} */}
                                {/* {this.state.Plano === 0 && this.state.session === '-1'}<br />
                                {this.state.Plano}<br />
                                {this.state.session} */}
                                <Row className='pb-3'>
                                    <Col xs={2}>
                                        {this.state.Plano > 0 && this.state.session !== '-1'
                                            ?
                                            <Botao
                                                FunctionClickCall={this.voltar}
                                                BotaoLink={true}
                                                className={"course-one__link font-size-20 lh-21"}
                                                value={getTraducao("Voltar")} />
                                            : <></>
                                        }
                                    </Col>

                                    <Col xs={10}>
                                    </Col>
                                </Row>
                                {/* {this.state.Plano === -1 || this.state.session === '-1'
                                    ? */}
                                <Row>

                                    {this.state.planos && this.state.planos.length > 0
                                        ?

                                        <>
                                            {
                                                this.state.planos.map(data =>
                                                    <Col className={"item mx-auto px-auto "} xs={4} key={data.id}>
                                                        <div className="course-one__single color-1 ">
                                                            <div className={"course-one__image color-" + data.order}>
                                                                {/* <img src={"./assets/images/" + data.imagem} alt="" /> */}
                                                            </div>{/*<!-- /.course-one__image -->*/}
                                                            <div className="course-one__content">
                                                                <h2 className="course-one__title font-size-36 lh-50 "> {data.titulo  + (this.isPlanosSelecionado(parseInt(data.id)) === 1 ? '*' : '')}</h2>

                                                                <div className="course-one__stars">
                                                                    <span className="course-one__stars-wrap">

                                                                    </span>
                                                                    {/*<!--span className="course-one__count">4.8</span-->*/}{/*<!-- /.course-one__count -->*/}
                                                                    {/*<!--span className="course-one__stars-count">250</span-->*/}{/*<!-- /.course-one__stars-count -->*/}
                                                                </div>{/*<!-- /.course-one__stars -->*/}
                                                                <div className="course-one__meta font-size-36 lh-36">
                                                                    R$ {data.valor}<br />
                                                                </div>{/*<!-- /.course-one__meta -->*/}
                                                                <div className={"course-one__meta-description-interna  font-size-18 lh-21 " + (this.state.Plano > 0 && this.state.session !== '-1' ? 'selecionado' : 'non-selecionado')}>
                                                                    {data.conteudo}

                                                                </div>
                                                                {this.state.Plano === -1 || this.state.session === '-1'
                                                                    ?
                                                                    <Link to="#" index={data.id} data-key={data.id} id={data.id} disabled={this.state.Plano > 0} onClick={this.setPlanoDataKey} className={"course-one__link font-size-20 lh-21 " + (this.isPlanosSelecionado(parseInt(data.id)) === 1 ? 'active ' : 'non-active')} >{getTraducao("Escolher")}</Link>
                                                                    :
                                                                    <></>}
                                                                {/*<!-- /.course-one__link -->*/}
                                                            </div>{/*<!-- /.course-one__content -->*/}
                                                        </div>{/*<!-- /.course-one__single -->*/}
                                                    </Col>
                                                )
                                            }

                                        </>

                                        :
                                        <p>{getTraducao("Carregando planos").toUpperCase()}</p>
                                    }
                                </Row>
                                {/* : <></>
                                } */}
                                {this.state.Plano > 0 && this.state.session !== '-1'
                                    ?
                                    <Row className=" pt-0 mt-0">
                                        <Col>
                                            <DirectPayment
                                                env={this.state.env}
                                                session={this.state.session}
                                                extraAmount={this.state.extraAmount}
                                                reference={this.state.reference}
                                                creditCard={this.state.creditCard}
                                                sender={this.state.sender}
                                                shipping={this.state.shipping}
                                                billing={this.state.billing}
                                                // hiddenShippingForm={this.state.hiddenShippingForm}
                                                // hiddenBillingForm={this.state.hiddenBillingForm}
                                                items={this.state.items}
                                                exclude={this.state.exclude}
                                                onError={this.onError.bind(this)}
                                                onSubmit={this.onSubmit.bind(this)}
                                            />
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                                }
                            </>
                        }

                    </Container>
                {/* </section> */}
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </>
        );
    }
}
export default NovaAssinatura;