import React, { Component } from 'react';
import PlanoDataService from "../services/plano.service";
// import OwlCarousel from 'react-owl-carousel';
//import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getToken } from '../utils';
import getTraducao from '../utils/traducao';

class BannerPlanos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planos: [],
      message: ""
    };

  }

  handleLogout = () => {

  }

  componentDidMount() {
    this.getPlanos();
  }

  getPlanos() {
    PlanoDataService.getAll()
      .then(response => {
        this.setState({
          planos: response.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }


  render() {


    return (
      <div>

        <section className="course-one__top-title home-one">
          <div className="container">
            <div className="block-title mb-0">
              <h2 className="block-title__title">{getTraducao("FAÇA JÁ")} <br />
              {getTraducao("sua assinatura")}</h2>{/*<!-- /.block-title__title -->*/}
            </div>{/*<!-- /.block-title -->*/}
          </div>{/*<!-- /.container -->*/}
          <div className="course-one__top-title__curve"></div>{/*<!-- /.course-one__top-title__curve -->*/}
        </section>{/*<!-- /.course-one__top-title -->*/}

        <section className="course-one course-one__teacher-details home-one">
          <Container>
            <Row>
              {this.state.planos !== undefined && this.state.planos.length > 0
                ?
                // <OwlCarousel className="course-one__carousel owl-carousel owl-theme" {...options}>
                <>
                  {
                    this.state.planos.map(data =>
                      <Col className="item" xs={12} sm={12} md={12} lg={4} key={data.id}>
                        <div className="course-one__single color-1">
                          <div className="course-one__image">
                            <img src={"./assets/images/" + data.imagem} alt="" />
                          </div>{/*<!-- /.course-one__image -->*/}
                          <div className="course-one__content">
                            <h2 className="course-one__title font-size-36 lh-50 ">{data.titulo}</h2>

                            <div className="course-one__stars">
                              <span className="course-one__stars-wrap">
                                {/* <div>
                                <i className={"fa " + (data.nota >= 1 ? "fa-star" : "")}></i>
                                <i className={"fa " + (data.nota >= 2 ? "fa-star" : "")}></i>
                                <i className={"fa " + (data.nota >= 3 ? "fa-star" : "")}></i>
                                <i className={"fa " + (data.nota >= 4 ? "fa-star" : "")}></i>
                                <i className={"fa " + (data.nota >= 5 ? "fa-star" : "")}></i>
                              </div> */}
                              </span>
                              {/*<!--span className="course-one__count">4.8</span-->*/}{/*<!-- /.course-one__count -->*/}
                              {/*<!--span className="course-one__stars-count">250</span-->*/}{/*<!-- /.course-one__stars-count -->*/}
                            </div>{/*<!-- /.course-one__stars -->*/}
                            <div className="course-one__meta font-size-20 lh-21">
                            {getTraducao("Investimento ")} R$ {data.valor}<br />
                            </div>{/*<!-- /.course-one__meta -->*/}
                            <div className="course-one__meta-description font-size-18 lh-21">
                              {data.descricao}
                            </div>

                            <Link to={(getToken() === undefined || getToken() === "" ||  getToken() === null ) ? "cadastre" : `nova-assinatura/${data.id}`} className="course-one__link font-size-20 lh-21">{getTraducao("Assinar")}</Link>{/*<!-- /.course-one__link -->*/}
                          </div>{/*<!-- /.course-one__content -->*/}
                        </div>{/*<!-- /.course-one__single -->*/}
                      </Col>
                    )
                  }
                </>
                // </OwlCarousel>
                :
                <p>{getTraducao("Carregando planos").toUpperCase()}</p>
              }
            </Row>
          </Container>{/*<!-- /.container -->*/}
        </section>
      </div >
    );
  }
}
export default BannerPlanos;