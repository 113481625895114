module.exports = {
    "Home": {
        "pt-BR": "Inicio",
        "en": "Home"
    },
    "Sobre nós": {
        "pt-BR": "Sobre nós",
        "en": "About us"
    },
    "Sobre Nós": {
        "pt-BR": "Sobre Nós",
        "en": "about Us"
    },
    "Saiba mais": {
        "pt-BR": "Saiba mais",
        "en": "Learn More"
    },
    "Assinatura": {
        "pt-BR": "Assinatura",
        "en": "My account"
    },
    "Contato": {
        "pt-BR": "Contato",
        "en": "Contact"
    },
    "Assinar": {
        "en": "Subscribe"
    },
    "Por": {
        "en": "By"
    },
    "Perfil": {
        "pt-BR": "Perfil",
        "en": "Profile"
    },
    "Dicionário": {
        "pt-BR": "Dicionário",
        "en": "Dictionary"
    },
    "Instrumentos": {
        "pt-BR": "Instrumentos",
        "en": "Instruments"
    },
    "Glossário": {
        "pt-BR": "Glossário",
        "en": "Glossary"
    },
    "Outros nomes": {
        "pt-BR": "Outros nomes",
        "en": "Other names"
    },
    "Bibliografia": {
        "pt-BR": "Bibliografia",
        "en": "Bibliography"
    },
    "Política de Privacidade": {
        "pt-BR": "Política de Privacidade",
        "en": "Privacy Policy"
    },
    "POLÍTICA DE":
    {
        "pt-BR": "POLÍTICA DE",
        "en": "PRIVACY"
    },

    "PRIVACIDADE":
    {
        "pt-BR": "PRIVACIDADE",
        "en": "POLICY"
    },
    "Sair":
    {
        "pt-BR": "Sair",
        "en": "Sign Out"
    },
    "Nome Original":
    {
        "en": "Original name"
    },
    "Nome":
    {
        "en": "Name"
    },
    "Fotografia":
    {
        "pt-BR": "Fotografia:",
        "en": "Photography:"
    },
    "Fechar":
    {
        "en": "Close"
    },
    "Execução":
    {
        "en": "Musical Performance"
    },
    "Fonte":
    {
        "en": "Source"
    },
    "Classificação":
    {
        "en": "Classification"
    },
    "SOBRE O": {
        "en": "about the".toUpperCase()
    },
    "DICIONÁRIO": {
        "en": "dictionary".toUpperCase()
    },
    "Existem dicionários de instrumentos musicais em várias partes do mundo, porém, o Dicionário Fontoura de Instrumentos Musicais, da maneira como foi estruturado, é único.": {
        "en": "There are many dictionaries of musical instruments all over the world. However, the way the Fontoura Dictionary of Musical Instruments is structured is unique. "
    },
    "Alguns são genéricos e apresentam apenas os verbetes, outros são específicos (de instrumentos percussão, de instrumentos étnicos, instrumentos regionais, instrumentos de orquestra, etc.). Entre eles, poucos são ilustrados e mais raros, ainda, são os que oferecem materiais complementares como fotos, vídeos ou áudios.": {
        "en": "Some dictionaries are generic and feature only the names of the instruments, others are too narrow and specific (dictionary of percussion, ethnic instruments, regional instruments, orchestra instruments, etc.). Few of them have illustrations, and those with complementary material such as pictures, videos and audios, are even more difficult to find. "
    },
    "O Dicionário Fontoura de Instrumentos Musicais tem a missão de, ao longo do tempo, organizar e disponibilizar um grande banco de dados composto por informações detalhadas de instrumentos musicais do mundo inteiro.": {
        "en": "The Fontoura Dictionary of Musical Instruments aims at organizing and providing a large, ongoing database, composed of detailed information on instruments from around the world. "
    },
    "A presente edição que, atualmente, já conta com mais de 600 verbetes, será continuamente expandida a 1300 verbetes.": {
        "en": "The current issue already has over 600 entries and it will soon expand to 1300 entries. "
    },
    "Esperamos que você aproveite ao máximo os conteúdos aqui disponibilizados.": {
        "en": "We hope that you enjoy all the content we provide here. "
    },
    "Boa leitura!": {
        "en": "Have fun! "
    },
    "COMO FAZER A": {
        "en": "HOW TO"
    },
    "PESQUISA": {
        "en": "FIND"
    },
    "Para localizar um determinado instrumento você deve procurá-lo no índice alfabético.": {
        "en": "To find a particular instrument you must search for its name in the alphabetical index.  "
    },
    "Alguns instrumentos possuem um único nome, outros, nomes diferentes, conforme a cultura em que estão inseridos. Há, ainda, a possibilidade de um mesmo nome indicar diferentes instrumentos.": {
        "en": "Some instruments have only one name, some have different designations, depending on the culture they are from. It is also possible that the same name may refer to different instruments."
    },
    "Os instrumentos que possuem diferentes nomes ou sinônimos foram organizados de modo a privilegiar o nome mais usual. Os demais nomes podem ser encontrados no índice geral de sinônimos. Deste modo, caso você não encontre um instrumento que você procura na lista principal, tente encontrá-lo no índice geral de sinônimos.": {
        "en": "The instruments with different names or synonyms were organized according to their most common designation. All other names can be found in the general index of synonyms. In case you cannot find an instrument on the main list, try searching for it in the general index of synonyms."
    },
    "Caso você não encontre o instrumento procurado na lista geral ou no índice geral de sinônimos, possivelmente este instrumento ainda não foi incluído no dicionário. Neste caso, você poderá entrar em contato com a nossa equipe técnica e, assim que possível, sua demanda poderá ser atendida.": {
        "en": "If you still are not able to find it there, it is possible that the instrument has not been yet included. In this case you may contact us and we will comply with your request as quick as possible. "
    },
    "Alguns instrumentos incluem somente o verbete. Outros incluem, também, fotos, áudios ou vídeos, disponibilizados pelos respectivos botões de acesso, quando destacados.": {
        "en": "Some instruments have only a brief definition of the term, others include pictures, audio, or videos, which can be accessed by clicking the respective buttons whenever they’re highlighted."
    },
    "USANDO O ": {
        "en": "How to use"
    },
    "GLOSSÁRIO": {
        "en": "the glossary"
    },
    "Termos técnicos referentes a partes de instrumentos, modos de execução, acessórios, entre outras especificidades, poderão ser encontrados no glossário, que integra nosso dicionário.": {
        "en": "Technical vocabulary referring to specific parts of musical instruments, playing techniques, its accessories, among other specificities can be found in the glossary available in our dictionary. "
    },
    "Algumas partes de instrumentos recebem diferentes nomes em diferentes partes do mundo. Por exemplo, a parte do violão onde se situa a cravelheira é chamada de “cabeça” por alguns e de “mão” por outros. Nesses casos, optamos pela nomenclatura mais usual e de uso frequente nas fontes de pesquisa de cada instrumento.": {
        "en": "Some parts of instruments can be named differently according to their location. For instance, the part of the guitar where the pegs are located can be called head or hand. In these cases, we chose the terms most commonly used and most frequently deployed in research sources. "
    },
    "Caso você tenha alguma dúvida a respeito de algum termo técnico utilizado nos verbetes, consulte o glossário.": {
        "en": "In case you have any question regarding technical terms used in the entries, you can search it in the glossary or contact us with your suggestions."
    },
    "Quero contribuir com uma": {
        "en": "I would like to contribute with"
    },
    "IDEIA ou MATERIAL": {
        "en": "an idea or material!"
    },
    "Caso você não encontre o instrumento que procura ou deseja conhecer, ou mesmo um termo técnico a ele relacionado, entre em contato com nossa equipe e envie-nos suas sugestões.": {
        "en": "If you cannot find the instrument you are looking for, or would like to know more about a specific instrument, or even about a technical term related to it, reach out to our team with your suggestions."
    },
    "Você conhece um instrumento musical de características singulares, ainda não contemplado em nosso dicionário? Entre em contato e conte-nos mais a respeito dele.": {
        "en": "Do you know an instrument with peculiar characteristics that is not yet included in our dictionary? Contact us and tell us more about it. "
    },
    "Caso você possua fotos, vídeos ou áudios de instrumentos musicais e queira compartilhá-los conosco, entre em contato para que possamos avaliar a inclusão de seu material em nosso banco de dados.": {
        "en": "In case you have photos, videos, or audio recordings of musical instruments and you want to share it with us, reach out and we will be happy to consider its inclusion in our database."
    },
    "PONDERAÇÕES": {
        "en": "Technical"
    },
    "TÉCNICAS": {
        "en": "considerations"
    },
    "SOBRE A": {
        "en": "about the"
    },
    "BIBLIOGRAFIA": {
        "en": "bibliography"
    },
    "Um mesmo instrumento pode ser tratado no feminino ou no masculino, a depender da língua. Buscamos referir tais instrumentos pela maneira mais usual.": {
        "en": "Differently from the English language, nouns in Portuguese are either masculine and feminine, and do not carry a gender-neutral pronoun. Therefore, we applied the most commonly used articles and pronouns according to literature and oral usage."
    },
    "Os instrumentos apresentam especificidades de execução quanto à lateralidade (mão, braço, perna, direita ou esquerda). Em todo caso, e de modo geral, as descrições da técnica performática utilizadas ao longo do dicionário referem-se à maneira mais comum de tocar o instrumento.": {
        "en": "The instruments feature specific playing techniques as for the laterality (right or left hand, arm, leg). The techniques described here refer to the most common way of playing the instrument. "
    },
    "Adotamos, na maioria das vezes, o sistema métrico de medida, exceto no caso de alguns instrumentos, como a cuíca, por exemplo, cujas medidas são normalmente determinadas segundo o sistema imperial inglês (polegadas).": {
        "en": "For the most part, we used the international system of units, except for some instruments, such as the cuíca, which measures are usually determined by the English imperial system of measurements (inches)."
    },
    "Os conteúdos dos verbetes são baseados em pesquisas diversificadas, amparadas na bibliografia referencial da área, bem como em pesquisas detalhadas in loco.": {
        "en": "The definitions available here are based on varied sources and research methods, including specialized secondary bibliography, as well as on field research."
    },
    "Consulte a bibliografia referencial, no menu ‘bibliografia’.": {
        "en": "You can find specialized references by clicking the ‘bibliography’ icon."
    },

    "Minhas assinaturas":
    {
        "pt-BR": "Minhas assinaturas",
        "en": "My Signatures"
    },
    //SEJA BEM VINDO
    "Seja bem-vindo ao Dicionário Fontoura de Instrumentos Musicais":
    {
        "en": "Welcome to the Fontoura Dictionary of Musical Instruments!"
    },
    "Cada povo constrói seus instrumentos musicais e com eles se relaciona de diferentes maneiras.":
        { en: "Each nation builds their musical instruments and relates to them in different ways." },
    "Estudá-los é mais do que explorar as suas formas, modos de tocar, sonoridades ou outras especificidades. É, também, conhecer parte da história, da sociologia, da geografia, da antropologia, entre outros aspectos culturais de seu povo de origem.":
        { en: "Studying them is more than just exploring their forms, ways of playing, their sounds or other specificities. It is also about getting to know part of the history, sociology, geography, anthropology, and other cultural aspects of its people." },
    "Aqui, você vai viajar por diversas partes do mundo, por meio dos seus instrumentos musicais.":
        { en: "You will travel to different parts of the world through the study of their musical instruments." },
    "O material de que são feitos, as técnicas de execução, a função onde estão inseridos (rituais, festividades, meditação, educação, ou simples lazer, entre outras), a complexidade dos instrumentos, sua evolução, o modo como se expandiram mundo afora são aspectos curiosos e relevantes da história da humanidade.":
        { en: "The material of which they are made, the playing techniques, their usage (in rituals, festivities, meditation, education, or simple leisure, among others), the complexity of the instruments, their evolutionary processes, the way they have spread throughout the world, are all part of curious and relevant aspects of human history." },
    "Quer experimentar esta viagem?":
        { en: "Would you like to join the journey?" },
    "Clique aqui":
        { en: "Click here" },
    "faça o seu cadastro e acesse, gratuitamente, alguns verbetes do Dicionário Fontoura de Instrumentos Musicais.":
        { en: "and sign up to access some of the entries from the Fontoura Dictionary of Musical Instruments free of charge." },
    //sobre nos
    "PROJETO": {
        en: "PROJECT"
    },
    "EQUIPE DE": {
        en: "Research and writing"
    },
    "PESQUISA & REDAÇÃO": {
        en: "TEAM"
    },
    "NOSSOS": {
        en: "Our"
    },
    "COLABORADoreS": {
        en: "Contributors"
    },
    "FICHA": {
        en: ""
    },
    "TÉCNICA": {
        en: "Credits"
    },
    "Entrar": {
        en: "Sign in"
    },
    "Senha": {
        en: "Password"
    }
    ,
    "Ainda não sou assinante": {
        en: "I'm not a subscriber yet"
    }
    ,
    "Perdeu a senha?": {
        en: "Lost your password?"
    },
    "ENTRAR": {
        en: "SIGN IN"
    },
    "Cadastrar": {
        en: "Sign up"
    },
    "FASE 1": {
        en: "PHASE 1"
    },
    "FAÇA JÁ": {
        en: "Subscribe "
    },
    "sua assinatura": {
        en: "Now"
    },
    "Cadastre seu e-mail": {
        en: "Register your email "
    },
    "e receba novidades": {
        en: "and get news"
    },

    "Projeto realizado com recursos do Programa de Apoio e Incentivo à Cultura - Fundação Cultural de Curitiba e da Prefeitura Municipal de Curitiba.": {
        en: "Project carried out with resources from the Culture Support and Incentive Program - Cultural Foundation of Curitiba and the Municipality of Curitiba."
    },
    "Cidade": { en: "City" },
    "DDD": { en: "Area code" },
    "Data Nascimento": { en: "Date of birth" },
    "Data de Nascimento": { en: "Date of birth" },
    "E-mail": { en: "Email" },
    "Outro (qual)": { en: "Other (which one)" },
    "Email": { en: "Email" },
    "Estado": { en: "State" },
    "FAQ": { en: "" },
    "Feminino": { en: "Female" },
    "Masculino": { en: "Male" },
    "Nenhum registro localizado": { en: "No record found" },
    "Prefiro não dizer": { en: "Rather not say" },
    "Gênero": { en: "Gender" },
    "Outro Gênero": { en: "Other Gender" },
    "Instituição": { en: "Institution" },
    "Nome Completo": { en: "Full name" },
    "Selecione uma opção": { en: "Select an option" },
    "Pais": { en: "Country" },
    "Profissao": { en: "Occupation" },
    "Profissão": { en: "Occupation" },
    "Confirmação de senha": { en: "Confirm your password" },
    "Confirmação de Senha": { en: "Confirm your Password" },
    "Senha ": { en: "Password" },
    "Telefone": { en: "Telephone" },
    "Processando seu cadastro. Aguarde...": { en: "Processing your registration. Wait..." },
    "Carregando dados do projeto": {en: "Loading project data"},
    "Carregando equipe": {en: "loading team"},
    "COLABORADORES": {en: "CONTRIBUTORS"},
    "Carregando equipe": {en: "loading team"},
    "Carregando Ficha Técnica": {en: "Loading Technical Data Sheet"},
    "Salvar": {en: "Save"},
    "Meus dados": {en: "My data"},
    "Alterar senha": {en: "Change password"},
    "Plano": {en: "Plan"},
    "Código da assinatura": {en: "Subscription code"},
    "Plano": {en: "Plan"},
    "Assinado em": {en: "Signed on"},
    "Válido até": {en: "Valid until"},
    "Transação": {en: "Transaction"},
    "Pago (sim, não)": {en: "Paid (yes, no)"},
    "Senha": {en: "Password"},
    "Nova senha": {en: "New password"},
    "Mínimo de seis (6) dígitos)": {en: "At least 6 characters"},
    "Em caso de dúvidas acesse o formulário de contato ": {en: "If you have any questions, please use the contact form"},
    "Confirmação da nova senha": {en: "Confirm new password"},
    "Enviar": {en: "Send"},

    "Incentivo": { en: "Incentive" },
    "Termos de uso e condições gerais": {
        en: "Terms of use and general conditions"
    },
    "Todos os direitos reservados.": {
        en: "All rights reserved. Copyright infringement is a violation of federal law subject to criminal and civil penalties. All texts, photos, illustrations, audios and videos in this book are protected by copyright (moral, patrimonial and related) and cannot be reproduced in whole or in part, or used in third-party publications without express written authorization of the authors or editors, except in the case of citation in scientific or educational works, provided that due credits are given:\n" +
            "Fontoura Dictionary of Musical Instruments\n" +
            "First edition, 2022\n" +
            "www.dicionariodeinstrumentos.com\n\r" +
            "2022 - Cântaro Arte Educação e Cultura\n",
        "pt-BR": "Todos os direitos reservados. A violação dos direitos do autor é infração, podendo sujeitar o infrator às penalidades prevista na lei de Direitos Autorais. Todos os textos, fotografias, ilustrações, áudios e vídeos, constantes neste dicionário, são protegidos por direitos autorais (morais, patrimoniais e conexos), não podendo ser reproduzidos integral ou parcialmente, ou usados em publicações de terceiros sem autorização expressa, por escrito, dos autores ou editor, exceto em caso de citação em trabalhos científicos ou educacionais, desde que dados os devidos créditos:\n" +
            "Dicionário Fontoura de Instrumentos Musicais\n" +
            "Primeira edição, 2022\n" +
            "www.dicionariodeinstrumentos.com\n" +
            "2022 - Cântaro Arte Educação e Cultura"
    }
    , "ENTRE EM": { en: "" },
    "CONTATO": { en: "CONTACT" },
    "Caso queira nos enviar uma sugestão ou tirar uma dúvida, envie sua mensagem preenchendo o formulário abaixo, e retornaremos o mais breve possível. A sua mensagem é importante para nós.": { en: "If you want to make a suggestion or have any questions, send us a message by filling out the form below. We will get back to you as soon as possible. Your message is very important to us" },
    "Nome:": { en: "Name:" },
    "Mensagem:": { en: "Message:" },
    "Concordo com a Política de Privacidade e com os Termos de Uso e Condições Gerais": { en: "I agree with the Privacy Policy and the Terms of Use and General Conditions" },
    "Gostaria de receber notícias, novidades e atualizações da Cântaro": { en: "I would like to get news and updates from Cântaro" },
    "ENVIAR": { en: "SEND" },
    "Oferecemos a você ou à sua instituição de ensino ou outra, algumas opções de planos para que você possa escolher o que melhor se adapta à sua necessidade.": { en: "We offer you or your institution educational or not some plan options so you can choose the one that best fits your needs." },
    "Em todos os planos você tem o acesso a todos os recursos do dicionário constituindo de verbetes, fotos, vídeos, áudios e ilustrações. A diferença de um plano para o outro consiste somente no período de tempo que você terá disponibilidade de acesso e o custo.": { en: "All plans include full access to the dictionary and its resources, meaning its entries, photos, videos, audios, and illustrations. The difference between the plans consists only in the time you will have access and the cost." },
    "Escolha a sua opção e faça a sua assinatura!": { en: "Choose your plan and subscribe!" },
    "escolha a sua opção e faça a sua assinatura!": { en: "choose your plan and subscribe!" },
    "Escolher": { en: "Choose" },
    "Voltar": { en: "Back" },
    "Investimento ": { en: "" },
    "TERMOS E CONDIÇÕES": { en: "Terms of use".toUpperCase() },
    "GERAIS DE USO": { en: "and general conditions".toUpperCase() }

};
