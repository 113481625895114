import React from "react";

const Select = props => {

    const { id, type, name, placeholder, required,onBlur, labelClass, defaultValue, value, onChange, className, validate, labelValue, autocomplete = 'off' } = props;

    return (
        <div className="form-group col-12 px-0">

            {
                labelValue === undefined ? "" : (
                    <label htmlFor={id} className={labelClass}>{labelValue}</label>
                )
            }
            <select
                id={id}
                name={name}
                type={type}
                autoComplete={autocomplete}
                required={required}
                validade={validate}
                className={className}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
            >
                {props.children}
            </select>
            {/*<p className={errorClass}>{fieldError}</p>*/}
        </div>
    );
}
export default Select