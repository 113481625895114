import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/estilo.css";
import "../assets/css/termo-condicoes.css";
import { Col, Container, Row } from 'react-bootstrap';
import Header from './Header';
import NavTop from './NavTop';
import NavMain from './NavMain';
import Footer from './Footer';
import FooterMenu from './FooterMenu';
import LoginDataService from "../services/login.service";
import getTraducao from '../utils/traducao';
import Botao from './Botao';
import TextInput from './TextInput';
import { getEmail } from '../utils';
// import { Form, ValidatedInput } from 'react-bootstrap-validation';
export default class AlterarSenha extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isSubmiting: true })

        if (event.target.NovaSenha.value !== event.target.NovaSenhaConfirma.value) {
            alert('As senhas devem ser iguais.');
            return
        }
        else {
            debugger
            let cadastro =
            {
                username:  (event.target.Email !== undefined ? event.target.Email.value : getEmail()),
                password: (this.props.match.params.codigo !== undefined ? event.target.NovaSenha.value : event.target.Senha.value),
                newPassword: event.target.NovaSenha.value,
                token: (this.props.match.params.codigo !== undefined ? this.props.match.params.codigo.trim() : undefined)
            };
            this.setState({ message: [] })


            if (this.props.match.params.codigo === undefined) {
                LoginDataService.changePassword(cadastro).then(res => {

                    if (res.status === 200) {

                        localStorage.setItem('message', res.data.message);
                        this.setState({ isSubmiting: false })
                        alert(res.data.message);
                         window.location = "/alterar-senha";
                    }
                }).catch(function (error) {
                    // this.setShow(this);
                    // var $ = require("jquery");

                    let message = 'Ocorreu um erro inesperado.';
                    if (error.response !== undefined) {
                        message = error.response.data.message;
                        // $(".has-error-message").text(message);
                        alert(message);
                        localStorage.setItem('message', message);
                    }
                    else
                        if (error.message !== undefined) {

                            message = error.message;
                            // $(".has-error-message").text(message);
                            alert(message);
                            localStorage.setItem('message', message);
                        }
                });
            }
            else {
                LoginDataService.changePasswordLost(cadastro).then(res => {

                    if (res.status === 200) {

                        localStorage.setItem('message', res.data.message);
                        this.setState({ isSubmiting: false })
                        alert(res.data.message);
                        window.location = "/login";
                    }
                }).catch(function (error) {
                    // this.setShow(this);
                    // var $ = require("jquery");

                    let message = 'Ocorreu um erro inesperado.';
                    if (error.response !== undefined) {
                        message = error.response.data.message;
                        // $(".has-error-message").text(message);
                        alert(message);
                        localStorage.setItem('message', message);
                    }
                    else
                        if (error.message !== undefined) {

                            message = error.message;
                            // $(".has-error-message").text(message);
                            alert(message);
                            localStorage.setItem('message', message);
                        }
                });
            }
        }
    }
    onChangeText = (event) => {
        this.setState({
            filtragem: event.target.value
        })
    }


    componentDidMount() {

    }

    carregarPolitica() {


    }

    render() {

        return (
            <div className="page-wrapper">
                <NavTop></NavTop>
                <NavMain></NavMain>
                <Header title="Alterar senha" ></Header>

                <Container className="termo-condicoes pt-4">
                    <Row>
                        <Col xs={6} className="px-5"><h3>{getTraducao("Alterar").toUpperCase()}</h3><h1>{getTraducao("senha").toUpperCase()}</h1></Col>
                        <Col xs={2} className="px-5"></Col>
                        <Col xs={4} className="px-5">

                        </Col>

                    </Row>
                </Container>
                <Container fluid={false}>
                    <form className='cadastro' onSubmit={this.handleSubmit}>

                        {
                            this.props.match.params.codigo !== undefined
                                ?
                                <>
                                    <Row className="px-auto mx-auto px-3 ">
                                        <Col>{getTraducao("Digite seu e-mail cadastrado no campo abaixo e escolha uma nova senha.")}</Col>
                                    </Row>
                                    <Row className="px-auto mx-auto px-3 mb-0">
                                        <Col xs={6}>
                                            <TextInput
                                                id="Email"
                                                name="Email"
                                                type="Email"
                                                placeholder="Email"
                                                required="required"

                                                className="form-control py-4"
                                                formGroupClass="px-0 mb-0"
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row className="px-auto mx-auto px-3 ">
                                        <Col>{getTraducao("Favor informar a senha atual, e a nova senha")}</Col>
                                    </Row>
                                    <Row className="px-auto mx-auto px-3 mb-0">
                                        <Col xs={3}>
                                            <TextInput
                                                id="Senha"
                                                name="Senha"
                                                type="password"
                                                placeholder="Senha"
                                                required="required"
                                                className="form-control py-4"
                                                formGroupClass="px-0"
                                                // validate="Cor"
                                                labelValue="Senha"
                                                data-minlength="6"
                                            // maxLength="15"
                                            // defaultValue={this.state.Cor}
                                            // onChange={this.OnChangeCor}
                                            // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                            />
                                            {/* <span className="help-block">Mínimo de seis (6) digitos</span> */}
                                        </Col>
                                    </Row>
                                </>
                        }
                        <Row className="px-auto mx-auto px-3 mb-0">
                            <Col xs={3}>
                                <TextInput
                                    id="NovaSenha"
                                    name="NovaSenha"
                                    type="password"
                                    placeholder="Nova Senha"
                                    required="required"
                                    className="form-control py-4"
                                    formGroupClass="px-0"
                                    // validate="Cor"
                                    labelValue="Senha"
                                    data-minlength="6"
                                // maxLength="15"
                                // defaultValue={this.state.Cor}
                                // onChange={this.OnChangeCor}
                                // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                />
                                <span class="help-block">Mínimo de seis (6) digitos</span>
                            </Col>
                            <Col xs={3}>
                                <TextInput
                                    id="NovaSenhaConfirma"
                                    name="NovaSenhaConfirma"
                                    type="password"
                                    placeholder="Confirmação da Nova Senha"
                                    required="required"
                                    className="form-control py-4"
                                    formGroupClass="px-0"
                                    dataMatch="#Senha"
                                    dataMatchError="Atenção! As senhas não estão iguais."
                                    // validate="Cor"
                                    dataMinLength="6"
                                    labelValue="Confirmação da Nova Senha"
                                // maxLength="15"
                                // defaultValue={this.state.Cor}
                                // onChange={this.OnChangeCor}
                                // readonly={(this.state.Id === "" || this.state.Id === undefined) ? "" : "readOnly"}
                                />
                                <div class="help-block with-errors"></div>
                            </Col>
                        </Row>
                        <Row className="px-auto mx-auto px-3 ">
                            <Col>{getTraducao("Em caso de dúvidas, acesse o formulário de contato.")}</Col>
                        </Row>
                        <Row className="px-auto mx-auto px-3 pb-5">
                            <Col xs={3}>
                                <Botao className={"course-one__link font-size-20 lh-21"} value="ENVIAR" />
                            </Col>
                        </Row>
                    </form>
                </Container>
                <FooterMenu></FooterMenu>
                <Footer></Footer>
            </div>
        );
    }

}
